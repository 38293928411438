import type { ReactNode } from 'react'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as Icons from './components/Icons'
import cover1 from './assets/covers/1.jpg'
import cover2 from './assets/covers/2.jpg'
import cover3 from './assets/covers/3.jpg'
import cover4 from './assets/covers/4.jpg'
import testimonials2 from './assets/testimonials/2.svg'
import testimonials3 from './assets/testimonials/3.svg'

export type Project = {
  icon: ReactNode
  type: ReactNode
  name: ReactNode
  link: string
  cover: string
  coverPosition?: string
  website?: string
  excerpt: ReactNode
  content: ReactNode[]
  stack: {
    productsAndStandards?: ReactNode[]
    backend?: ReactNode[]
    frontend?: ReactNode[]
    infrastructure?: ReactNode[]
  }
}

const projects: Project[] = [
  {
    icon: <Icons.AccountBalance />,
    type: <>Fintech</>,
    name: <>Loans</>,
    link: '/case-studies/fintech-loans',
    cover: cover1,
    excerpt: (
      <>
        A client from the lending industry operating in 3 countries used various fragmentary
        solutions that were not adapted to their needs.
      </>
    ),
    content: [
      <>
        <span className="underlined">Context and client's need</span>
        <h2>
          A client from the lending industry operating in 3 countries used various fragmentary
          solutions that were not adapted to their needs.
        </h2>
        <p>
          They needed a unified system, adjusted to the countries' local requirements. The core
          banking system specified by the client was Mambu.
        </p>
        <p>
          An additional requirement was a possibility to easily adapt the system to new countries.
        </p>
      </>,
      <>
        <span className="underlined">What needed to be done?</span>
        <h2>
          Our task was to develop a platform based on BaaS (Banking as a Service) Mambu system. We
          used our previous experiences not only to integrate, but also to prepare loan origination
          forms and business processes.
        </h2>
        <p>
          The client also needed a proper EOD (End Of Day) process, extended with operations
          specific to each of the countries.
        </p>
      </>,
      <>
        <span className="underlined">What we achieved</span>
        <h1>
          We created scalable solutions tailored to the client's needs as well as to the legislative
          requirements of individual countries, including IFRS 9 accounting standard.
        </h1>
        <p>
          We maintained continuity of operation across all three countries in which the client was
          running the lending business.
        </p>
        <p>
          We prepared the entire software so that it would be possible within a few weeks to connect
          another country in which the client would like to start granting loans.
        </p>
      </>
    ],
    stack: {
      productsAndStandards: [<Icons.MambuLogo />, <Icons.IfrsLogo />],
      backend: [<Icons.JavaLogo />, <Icons.SpringLogo />, <Icons.SpockLogo />, <Icons.MavenLogo />],
      frontend: [<Icons.ReactLogo />, <Icons.GreensockLogo />, <Icons.CypressLogo />],
      infrastructure: [
        <Icons.MysqlLogo />,
        <Icons.MongodbLogo />,
        <Icons.RabbitmqLogo />,
        <Icons.AwsLogo />
      ]
    }
  },
  {
    icon: <Icons.CreditScore />,
    type: <>Fintech</>,
    name: <>Collection</>,
    link: '/case-studies/fintech-collection',
    cover: cover2,
    coverPosition: '70% center',
    excerpt: (
      <>
        A big FinTech company went through a transformation. From a broadly defined financial
        activity, they changed the scope to debt collection services.
      </>
    ),
    content: [
      <>
        <span className="underlined">Context and client's need</span>
        <h2>
          A big FinTech company went through a transformation. From a broadly defined financial
          activity, they changed the scope to debt collection services.
        </h2>
        <p>
          After changing the type of business activity previously used tools and systems turned out
          to be too expensive.
        </p>
        <p>
          Facing new tasks and the need to reduce costs, the company was lacking a functional and
          budget-friendly system, dedicated to handling and collecting debt.
        </p>
      </>,
      <>
        <span className="underlined">What needed to be done?</span>
        <h2>
          Our task was to prepare a new, built from the ground up system for debt management and
          collection.
        </h2>
        <p>
          We resigned from an extensive complex system in favour of a tailor-made one, created by us
          from scratch. Relying on our background in the loan and debt collection industry, we
          proposed new tools and implemented a personalised solution.
        </p>
      </>,
      <>
        <span className="underlined">What we achieved</span>
        <h1>We provided a personalised billing and collection system.</h1>
        <p>
          We optimised costs by replacing the large micro-service architecture with the solution
          prepared by us - a simplified core banking system.
        </p>
        <p>
          We implemented recurring payments (SEPA Direct Debit), statement payment processing and
          implemented UI for debt collection service.
        </p>
        <p>
          For over 12 months, we have ensured the continuity of operation of all components without
          interrupting the system activity.
        </p>
        <p>The client has been collecting receivables in an effective and cost-optimised way.</p>
      </>
    ],
    stack: {
      productsAndStandards: [<Icons.SepaLogo />],
      backend: [<Icons.JavaLogo />, <Icons.SpringLogo />, <Icons.SpockLogo />, <Icons.MavenLogo />],
      infrastructure: [<Icons.MysqlLogo />, <Icons.AwsLogo />]
    }
  },
  {
    icon: <Icons.DisplaySettings />,
    type: <>Fintech</>,
    name: <>Banking</>,
    link: '/case-studies/fintech-banking',
    website: 'https://aion.eu/en/',
    cover: cover3,
    coverPosition: '60% center',
    excerpt: (
      <>
        The client did not possess their own solution to manage SWIFT payments. They needed a system
        for independent management of international transfers.
      </>
    ),
    content: [
      <>
        <span className="underlined">Context and client's need</span>
        <h2>The client did not possess their own solution to manage SWIFT payments.</h2>
        <p>They needed a system for independent management of international transfers.</p>
      </>,
      <>
        <span className="underlined">What needed to be done?</span>
        <h2>
          Our task was to prepare a solution for processing transactions through SWIFT system, which
          would fit into the client's ecosystem.
        </h2>
        <p>Also it was necessary to apply changes without interruptions in the system activity.</p>
      </>,
      <>
        <span className="underlined">What we achieved</span>
        <h2>
          We proposed and conducted a multi-stage implementation of SWIFT standard. We adapted the
          new solution to the client's architecture and infrastructure.
        </h2>

        <p>
          We changed non-scalable payment software to a dedicated one which not only allowed for
          handling the current number of transactions but also ensured continuity of operations for
          much larger data volume.
        </p>
        <p>
          We optimised costs through implementation of key functionalities and we fitted them into
          existing components of current architecture.
        </p>
        <p>We carried out all actions in cooperation with the client's IT department.</p>
        <p>The changes were unnoticeable for the company's customers and were made in real time.</p>
      </>
    ],
    stack: {
      productsAndStandards: [<Icons.SwiftLogo />],
      backend: [
        <Icons.JavaLogo />,
        <Icons.SpringLogo />,
        <Icons.SpockLogo />,
        <Icons.GradleLogo />
      ],
      infrastructure: [<Icons.PosgresqlLogo />]
    }
  },
  {
    icon: <Icons.WindPower />,
    type: <>Energy industry</>,
    name: <>Web platform</>,
    link: '/case-studies/energy-industry-web-platform',
    website: 'https://ozeos.pl/',
    cover: cover4,
    coverPosition: '80% center',
    excerpt: (
      <>
        The client needed a web platform, which would allow easy connection of producers and
        consumers of renewable energy.
      </>
    ),
    content: [
      <>
        <span className="underlined">Context and client's need</span>
        <h2>
          The client needed a web platform, which would allow easy connection of producers and
          consumers of renewable energy. It was crucial to prepare a solution which would select the
          best combination of energy sources suited for customers needs.
        </h2>
        <p>
          The purpose of proposed IT solutions was to facilitate the selection of energy suppliers,
          economically highlight the benefits of renewable energy and to support in the preparation
          of the PPA.
        </p>
        <p>
          We had been working with the client since the concept phase, proposing technical
          tailor-made solutions, which we then converted into a finished platform.
        </p>
      </>,
      <>
        <span className="underlined">What needed to be done?</span>
        <h2>
          The client needed support in terms of forming a business vision of both the conceptual
          solution and the delivery of an IT solution, which we implemented within a few months.
        </h2>
        <p>
          We have prepared a prototype of an energy profile comparison tool with a module for
          selecting the best combination of green energy sources for the consumer (a novelty on the
          Polish market). We proposed and implemented scalable solutions for the whole project.
        </p>
      </>,
      <>
        <span className="underlined">What we achieved</span>
        <h1>
          We provided full support from the design stage, through deployment, up to current project
          service.
        </h1>
        <p>We implemented a full solution from infrastructure through backend to frontend.</p>
        <p>
          By developing the project since the beginning, we offered professional support and IT
          service throughout the whole platform creation process.
        </p>
        <p>
          The registration, profiling and matching platform we have created allows for connecting
          consumers and producers of green energy. It is the fastest matching platform on the Polish
          market.
        </p>
      </>
    ],
    stack: {
      backend: [
        <Icons.JavaLogo />,
        <Icons.SpringLogo />,
        <Icons.SpockLogo />,
        <Icons.MavenLogo />,
        <Icons.PlaywrightLogo />,
        <Icons.GradleLogo />
      ],
      frontend: [<Icons.FigmaLogo />, <Icons.ReactLogo />, <Icons.GatsbyLogo />],
      infrastructure: [<Icons.PosgresqlLogo />, <Icons.MongodbLogo />, <Icons.AwsLogo />]
    }
  }
]

const currentYear = new Date().getFullYear()

const en = {
  navigation: {
    offer: { label: <>What we offer</>, url: '/#offer' },
    caseStudies: { label: <>Case studies</>, url: '/#case-studies' },
    people: { label: <>People</>, url: '/#people' },
    contact: { label: <>Contact us</>, url: '/#contact' }
  },
  contact: {
    phone: '+48 794 488 927',
    email: 'contact@impactdev.io',
    recruitmentEmail: 'recruitment@impactdev.io',
    linkedIn: 'https://www.linkedin.com/company/impact-development'
  },
  footer: {
    logo: <>Logo</>,
    quickLinks: <>Quick links</>,
    contactUs: <>Contact us</>,
    slogan: <>Development of your ideas</>,
    copyright: <>© IMPACT Development {currentYear}</>
  },
  home: {
    intro: {
      title: <>Development of your ideas</>,
      subtitle: <>Scalable products in weeks not months</>
    },
    offer: {
      underlined: <>What we offer</>,
      h1: (
        <>
          So far we did <strong>7 projects</strong>
          <br />
          in <strong>5 countries</strong> for <strong>1MM users</strong>
        </>
      ),
      p1: (
        <>
          We help clients succeed by proposing modern, tailor-made IT solutions.
          <br />
          We specialise in FinTech industry, including comprehensive consulting in Mambu ecosystem.
        </>
      ),
      p2: (
        <>
          We create functional solutions. We execute projects from the concept stage, through coding
          and architecture, to deployment and maintenance.
          <br />
          We execute complex projects within the planned project schedule.
        </>
      ),
      flow: <>We execute complex IT projects within the set deadline</>,
      tools: <>Powerful tools for growing businesses</>,
      backend: <>Backend</>,
      frontend: <>Frontend</>,
      infrastructure: <>Infrastructure</>
    },
    caseStudies: {
      underlined: <>Case studies</>,
      h1: (
        <>
          Check what we had already faced and how <strong>it turned out</strong>
        </>
      ),
      p: (
        <>
          Specific examples of challenges presented to us by companies who trusted us along with
          solutions which strengthened this trust.
        </>
      ),
      readMore: <>Read more</>
    },
    people: {
      underlined: <>People</>,
      h1: (
        <>
          People <strong>you can count on</strong>
        </>
      ),
      p: (
        <>
          Any solution is only as good as the people who implement them.
          <br />
          See who makes our projects stand out on the market.
        </>
      ),
      people: [
        {
          name: <>Piotr Kamoda</>,
          position: <>Co-founder</>,
          photo: <StaticImage src="./assets/people/piotr-kamoda.jpg" alt="" />,
          linkedIn: 'https://www.linkedin.com/in/piotr-kamoda-28029671/'
        },
        {
          name: <>Patryk Hajduk</>,
          position: <>Co-founder</>,
          photo: <StaticImage src="./assets/people/patryk-hajduk.jpg" alt="" />,
          linkedIn: 'https://www.linkedin.com/in/patrykhajduk/'
        }
      ],
      joinUs: {
        h1: <>Join us</>,
        p: [
          <>
            If you like dotting all the I's and crossing all the T's, and a complex project with a
            strong team motivates you - send us your application.
          </>,
          <>
            Our clients are companies that need professional and thoughtful IT solutions,
            implemented within the agreed timeline.
          </>,
          <>
            We work together. We talk, we look for solutions, we share experiences.
            <br />
            Does it suit you? Let us know!
          </>
        ]
      }
    },
    recommedations: {
      h2: (
        <>
          We are
          <br />
          <strong>recommended</strong>
        </>
      ),
      p: (
        <>
          Look at us from the perspective
          <br />
          of customers who trusted us.
        </>
      ),
      items: [
        {
          name: <>Krzysztof Komosa</>,
          position: <>CEO of Monedo Poland </>,
          photo: <StaticImage src="./assets/testimonials/1.png" alt="" />,
          text: (
            <>
              The Team helped us to maintain Polish loan portfolio in difficult time of Covid and
              bankruptcy of Monedo Holding. Support was driven efficiently and within budget. Their
              expertise helped us also in the acquisition process where they led the technical part
              of that process.
            </>
          )
        },
        {
          name: <>Board of Ozeos.pl</>,
          photo: <img src={testimonials2} alt="" />,
          text: (
            <>
              Impact Development successfully helped us from idea to finished product. The project
              was driven professionally and delivered on time without any obstacles. We fully
              recommend the Team for any project.
            </>
          )
        },
        {
          name: <>Institutional client of Impact Development</>,
          photo: <img src={testimonials3} alt="" />,
          text: (
            <>
              Impact Development assisted us with the migration and maintenance of Spanish and
              Polish loan portfolios for more than 2 years. The project was managed effectively and
              the team worked hard to ensure everything went smoothly.
            </>
          )
        }
      ]
    },
    contact: {
      underlined: <>Contact us</>,
      h1: <>Let's talk</>,
      p: <>Let's discuss your idea and make impact on the market.</>,
      phone: {
        icon: <Icons.Phone />,
        title: <>Phone</>
      },
      email: {
        icon: <Icons.Send />,
        title: <>Email</>
      },
      linkedin: {
        icon: <Icons.BusinessCenter />,
        title: <>LinkedIn</>,
        label: <>Click here</>
      }
    }
  },
  caseStudies: {
    enterTheWebsite: <>Enter the website</>,
    scrollDown: <>Scroll down</>,
    stack: <>Key tools and working methods</>,
    productsAndStandards: <>Products and Standards</>,
    backend: <>Backend</>,
    frontend: <>Frontend</>,
    infrastructure: <>Infrastructure</>,
    projects
  },
  jeeFloLink: {
    text: (
      <>
        <strong>
          <em>Revolutionise</em> your product
          <br />
        </strong>
        using our platform JeeFlo
      </>
    ),
    alt: 'JeeFlo logo',
    url: 'https://jeeflo.com/'
  }
}

export default { pl: en, en }
