import { ReactElement, useEffect } from 'react'
import type { Project } from '../copy'
import React, { cloneElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useContent } from '../content'
import Arrow from '../components/Arrow'
import ArrowLink from '../components/ArrowLink'
import Section from '../components/Section'
import backgroundBlur from '../assets/background-blur.jpg'

type Props = {
  project: Project
}

export default function CaseStudy({ project }: Props) {
  const { caseStudies: texts } = useContent().content

  return (
    <main>
      <Intro $cover={project.cover} $coverPosition={project.coverPosition}>
        <Heading>
          {project.icon}

          <Name>
            {project.type}
            <strong>{project.name}</strong>
          </Name>
        </Heading>

        <ScrollDownButton to="#content">
          {texts.scrollDown} <Arrow direction="down" fill="var(--turquoise)" />
        </ScrollDownButton>
      </Intro>

      <Content id="content">
        {project.content.map((section, index) => (
          <div key={index}>{section}</div>
        ))}

        {project.website && (
          <WebsiteLink>
            <ArrowLink href={project.website}>{texts.enterTheWebsite}</ArrowLink>

            <span>{project.website}</span>
          </WebsiteLink>
        )}
      </Content>

      <Stack className="dark">
        <h2>{texts.stack}</h2>

        {project.stack.productsAndStandards && (
          <>
            <span className="underlined">{texts.productsAndStandards}</span>
            <Logos>
              {project.stack.productsAndStandards.map((logo, index) =>
                cloneElement(logo as ReactElement, { key: index })
              )}
            </Logos>
          </>
        )}

        {project.stack.backend && (
          <>
            <span className="underlined">{texts.backend}</span>
            <Logos>
              {project.stack.backend.map((logo, index) =>
                cloneElement(logo as ReactElement, { key: index })
              )}
            </Logos>
          </>
        )}

        {project.stack.frontend && (
          <>
            <span className="underlined">{texts.frontend}</span>
            <Logos>
              {project.stack.frontend.map((logo, index) =>
                cloneElement(logo as ReactElement, { key: index })
              )}
            </Logos>
          </>
        )}

        {project.stack.infrastructure && (
          <>
            <span className="underlined">{texts.infrastructure}</span>
            <Logos>
              {project.stack.infrastructure.map((logo, index) =>
                cloneElement(logo as ReactElement, { key: index })
              )}
            </Logos>
          </>
        )}
      </Stack>
    </main>
  )
}

export const Intro = styled(Section)<{
  $cover: Project['cover']
  $coverPosition: Project['coverPosition']
}>`
  flex: none;
  display: flex;
  flex-direction: column;
  height: 100svh;
  padding-bottom: 32px;
  background-image: linear-gradient(110deg, var(--turquoise_05), var(--pink_05)),
    url('${({ $cover }) => $cover}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    padding: 16px 32px;
    background-position: center, ${({ $coverPosition }) => $coverPosition || 'center'};
  }
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--8);
  margin-top: auto;
  margin-bottom: 80px;

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
  }

  svg {
    width: var(--64);
    height: var(--64);
  }
`

const Name = styled.div`
  display: flex;
  flex-direction: column;
  font: var(--500-21);

  @media (min-width: 769px) {
    font: var(--500-34);
  }

  strong {
    font: var(--900-34);

    @media (min-width: 769px) {
      font: var(--900-55);
    }
  }
`

const ScrollDownButton = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--900-16);

  @media (min-width: 769px) {
    font: var(--900-21);
  }
`

const WebsiteLink = styled.div`
  margin-top: calc(var(--40) * -1);

  > span {
    font: var(--400-16);
  }
`

const Content = styled(Section)`
  gap: var(--64);

  > div {
    display: flex;
    flex-direction: column;

    &:not(${WebsiteLink}) {
      gap: 16px;
    }

    > h1 {
      margin-bottom: 0;
    }

    > .underlined {
      margin-bottom: -8px;
    }

    > p + p {
      margin-top: var(--8);
    }
  }
`

const Stack = styled(Section)`
  gap: var(--32);
  background-image: url('${backgroundBlur}');
  background-position: calc(50% - 50px) top;
  background-repeat: no-repeat;
  background-size: 2000px 100%;

  @media (min-width: 769px) {
    align-items: center;
  }

  .underlined {
    text-transform: unset;
  }
`

const Logos = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;

  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;
  }
`
