import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from '@reach/router'
import { useContent } from '../content'
import MobileMenu from '../components/MobileMenu'
import Section from '../components/Section'
import { Logo } from '../components/Icons'
import { Intro } from '../templates/CaseStudy'

export default function Header() {
  const location = useLocation()
  const { navigation } = useContent().content
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const lightMode = location.pathname.startsWith('/case-studies/')

  return (
    <StyledHeader
      as="header"
      className={lightMode ? 'light' : 'dark'}
      $mobileMenuOpen={mobileMenuOpen}
    >
      <a href="/" aria-label="Home">
        <Logo />
      </a>

      <DesktopLinks>
        <a href={navigation.offer.url}>{navigation.offer.label}</a>
        <a href={navigation.caseStudies.url}>{navigation.caseStudies.label}</a>
        <a href={navigation.people.url}>{navigation.people.label}</a>
        <a href={navigation.contact.url}>{navigation.contact.label}</a>
      </DesktopLinks>

      <MobileMenu onOpen={setMobileMenuOpen} />
    </StyledHeader>
  )
}

const StyledHeader = styled(Section)<{ $mobileMenuOpen: boolean }>`
  flex-direction: row;
  gap: 32px;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  ${({ $mobileMenuOpen }) =>
    $mobileMenuOpen &&
    css`
      @media (max-width: 768px) {
        > a,
        > button,
        + main > div:first-child > section,
        + main ${Intro} * {
          visibility: hidden;
        }
      }
    `}

  @media (min-width: 769px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &.dark {
    background: none;
  }

  > a {
    margin-right: auto;

    @media (max-width: 768px) {
      svg {
        width: 136px;
      }
    }
  }
`

const DesktopLinks = styled.nav`
  @media (max-width: 768px) {
    display: none;
  }

  display: flex;
  align-items: center;
  gap: inherit;

  > a {
    text-align: center;

    &:not(:last-child) {
      display: flex;
      align-items: center;
      height: 74px;
      border-bottom: 1px solid transparent;
      transition: border-color 0.2s;

      &:hover {
        border-bottom: 1px solid;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 64px;
      border: 1px solid;
      border-radius: 130px;
      color: var(--turquoise);
      font: var(--900-21);
      transition:
        background 0.2s,
        color 0.2s;

      .light & {
        color: var(--black);
      }

      &:hover {
        background: var(--turquoise);
        color: var(--black);

        .light & {
          background: var(--black);
          border-color: var(--black);
          color: var(--white);
        }
      }
    }
  }
`
