import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Arrow from './Arrow'

type Props = {
  to?: string
  href?: string
} & typeof StyledAnchor.defaultProps &
  typeof StyledLink.defaultProps

export default function ArrowLink({ to, href, children, ...props }: Props) {
  if (to)
    return (
      <StyledLink to={to} {...props}>
        {children}
        <Arrow fill="var(--turquoise)" />
      </StyledLink>
    )

  if (href)
    return (
      <StyledAnchor href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
        <Arrow fill="var(--turquoise)" />
      </StyledAnchor>
    )

  return null
}

const styles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--900-16);

  &:hover svg {
    transform: translateX(50%);
  }

  @media (min-width: 769px) {
    font: var(--900-21);
  }

  svg {
    transition: transform 0.2s ease-in-out;

    .dark & {
      fill: var(--pink);
    }

    .light & {
      fill: var(--turquoise);
    }
  }
`

const StyledAnchor = styled.a`
  ${styles}
`

const StyledLink = styled(Link)`
  ${styles}
`
