import React, { useEffect } from 'react'
import ContentProvider from './src/content'
import Header from './src/components/Header'
import Footer from './src/components/Footer'
import GlobalStyles from './src/styles'

function App({ children }) {
  return (
    <ContentProvider>
      <GlobalStyles />
      <Header />
      {children}
      <Footer />
    </ContentProvider>
  )
}

export const wrapPageElement = ({ element }) => <App>{element}</App>
