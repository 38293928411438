import React, { type HTMLAttributes } from 'react'
import { styled } from 'styled-components'
import Arrow from './Arrow'
import jeeFloLogo from '../assets/jeeflo-logo.svg'
import { useContent } from '../content'

export default function JeeFloLink({ className, ...props }: HTMLAttributes<HTMLAnchorElement>) {
  const { jeeFloLink } = useContent().content

  return (
    <Wrapper
      className={className ? className + ' dark' : 'dark'}
      href={jeeFloLink.url}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <Logo src={jeeFloLogo} alt={jeeFloLink.alt} />

      <Text>
        <span>{jeeFloLink.text}</span>
        <Arrow fill="var(--turquoise)" />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: var(--16);
  border-radius: 8px 0 0 8px;
  background: rgba(255, 255, 255, 0.13);
  z-index: 1;

  @media (max-width: 768px) {
    align-self: stretch;
    margin: 0 -16px;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }

  &:hover > div > svg {
    transform: translateX(25%);
  }

  em {
    color: var(--turquoise);
    font-style: normal;
  }
`

const Logo = styled.img`
  width: 32px;
  height: 32px;

  @media (min-width: 769px) {
    width: 48px;
    height: 48px;
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  line-height: 25px;

  @media (min-width: 769px) {
    gap: 32px;
    line-height: 35px;
  }

  svg {
    transition: transform 0.2s ease-in-out;

    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
`
