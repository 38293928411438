import type { ReactNode } from 'react'
import type { Locale } from 'date-fns'
import React, { useEffect, createContext, useContext, useState } from 'react'
import { pl, enUS } from 'date-fns/locale'
import copy from './copy'

type Language = 'pl' | 'en'

const ContentContext = createContext<{
  language: Language
  locale: Locale
  content: typeof copy.en
  changeLanguage: (language: Language) => void
}>({
  language: 'en',
  locale: enUS,
  content: copy.en,
  changeLanguage: () => {}
})

export const useContent = () => useContext(ContentContext)

type Props = {
  children: ReactNode
}

export default function ({ children }: Props) {
  const [language, setLanguage] = useState<Language>('en')
  const locale = { pl, en: enUS }

  useEffect(() => {
    document.documentElement.lang = language
  }, [language])

  return (
    <ContentContext.Provider
      value={{
        language,
        locale: locale[language],
        content: copy[language],
        changeLanguage: setLanguage
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}
