import React from 'react'
import styled from 'styled-components'

type Props = {
  $direction?: 'left' | 'right' | 'up' | 'down'
  $small?: boolean
}

export default function Arrow({
  $direction = 'right',
  fill = 'var(--black)',
  ...props
}: Props & typeof Svg.defaultProps) {
  return (
    <Svg direction={$direction} viewBox="0 0 48 48" fill={fill} {...props}>
      <path d="m28 35.9-2-2.1 8.4-8.4H8v-3h26.3l-8.4-8.5 2.1-2 12 12-12 12Z" />
    </Svg>
  )
}

const Svg = styled.svg<Props>`
  width: 24px;
  height: 24px;

  @media (min-width: 769px) {
    width: ${({ $small }) => ($small ? 24 : 48)}px;
    height: ${({ $small }) => ($small ? 24 : 48)}px;
  }

  ${({ direction }) => {
    switch (direction) {
      case 'left':
        return 'transform: rotate(180deg);'
      case 'right':
        return 'transform: rotate(0deg);'
      case 'up':
        return 'transform: rotate(-90deg);'
      case 'down':
        return 'transform: rotate(90deg);'
    }
  }}
`
