import type { MouseEvent } from 'react'
import { useEffect } from 'react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useContent } from '../content'
import * as Icons from '../components/Icons'
import JeeFloLink from './JeeFloLink'

export default function MobileMenu({ onOpen }: { onOpen: (isOpen: boolean) => void }) {
  const { navigation, contact } = useContent().content
  const [isOpen, setIsOpen] = useState(false)

  const handleLinkClick = (event: MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLButtonElement)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) document.body.classList.add('mobile-lock-scroll')
    else document.body.classList.remove('mobile-lock-scroll')
    onOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <MenuButton
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icons.Menu />
      </MenuButton>

      {isOpen && (
        <Menu>
          <Header>
            <Icons.Logo />

            <MenuButton onClick={() => setIsOpen(!isOpen)}>
              <Icons.Close />
            </MenuButton>
          </Header>

          <Links onClick={handleLinkClick}>
            <a href={navigation.offer.url}>{navigation.offer.label}</a>
            <a href={navigation.caseStudies.url}>{navigation.caseStudies.label}</a>
            <a href={navigation.people.url}>{navigation.people.label}</a>
            <a href={navigation.contact.url}>{navigation.contact.label}</a>
          </Links>

          <JeeFloLink className="mobile-only" />

          <Contact>
            <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </Contact>
        </Menu>
      )}
    </>
  )
}

const Menu = styled.div`
  @media (min-width: 769px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;

  width: 100vw;
  height: 100svh;
  padding: 4px 16px 8px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  overflow-y: auto;

  .dark & {
    backdrop-filter: blur(15px);
  }

  .light & {
    background: linear-gradient(140deg, var(--turquoise_09) 7%, var(--pink_09) 76%);
  }

  a:hover {
    text-decoration: underline;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;

  > svg {
    width: 136px;
  }
`

const MenuButton = styled.button`
  @media (min-width: 769px) {
    display: none;
  }

  display: flex;
  margin-top: -20px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`

const Links = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  font: var(--500-21);
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font: var(--500-16);
`
