import styled from 'styled-components'

export default styled.section`
  --padding: 64px;

  display: flex;
  flex-direction: column;
  padding: var(--padding) var(--max-content);

  &.dark {
    background-color: var(--black);
  }

  @media (min-width: 769px) {
    --padding: 128px;
  }
`
